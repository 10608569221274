import { useNavigate } from "react-router-dom"
import { Button } from "~/ui/button"
import { aboutYouPath, onboardingInterestsPath } from "~/common/paths"
import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { OnboardingTitle } from "~/ui/Onboarding"
import { Trans } from "react-i18next"
import { OnboardingExperienceQuestions } from "~/onboarding/OnboardingExperienceQuestions"
import { useCallback } from "react"
import { useCurrentUser } from "~/auth/CurrentUserContext"

export const ExperienceScreen = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUser()

  const onBack = async () => {
    navigate(aboutYouPath.pattern)
  }

  const onSave = useCallback(() => {
    navigate(onboardingInterestsPath.pattern)
  }, [navigate])

  return (
    <OnboardingLayout step={1}>
      <OnboardingTitle>
        <Trans i18nKey="experienceScreen.title" ns="onboarding" />
      </OnboardingTitle>
      <div className="w-full md:min-w-128">
        <OnboardingExperienceQuestions
          fitProfileDefaults={currentUser.fitProfile}
          onSave={onSave}
          footer={(loading) => (
            <div className="onboarding-footer">
              <div
                className="hidden md:block onboarding-footer-link"
                onClick={onBack}
              >
                Back to All About You
              </div>
              <Button variant="onboarding" type="submit" disabled={loading}>
                Continue
              </Button>
              <div
                className="md:hidden onboarding-footer-link"
                onClick={onBack}
              >
                Back to All About You
              </div>
            </div>
          )}
        />
      </div>
    </OnboardingLayout>
  )
}
