import { UserSortEnum } from "~/__generated__/graphql"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { SearchInput } from "~/ui/SearchInput"

type UsersSearchProps = {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  sort: UserSortEnum
  setSort: (sort: UserSortEnum) => void
  sortOptions?: UserSortEnum[]
}

export const UsersSearch = ({
  searchTerm,
  setSearchTerm,
  sort,
  setSort,
  sortOptions = [
    UserSortEnum.Recent,
    UserSortEnum.Name,
    UserSortEnum.ApplicationPriority,
  ],
}: UsersSearchProps) => {
  return (
    <div className="flex gap-2">
      <div className="flex-grow">
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      {sortOptions.length > 1 && (
        <div>
          <Select
            onValueChange={(value) => setSort(value as UserSortEnum)}
            value={sort}
          >
            <SelectTrigger className="text-sm min-w-[162px]">
              <SelectValue placeholder="" />
            </SelectTrigger>
            <SelectContent>
              {sortOptions.map((option) => (
                <SelectItem key={option} value={option}>
                  {option === UserSortEnum.ApplicationPriority &&
                    "Application Priority"}
                  {option === UserSortEnum.Name && "Name (A-Z)"}
                  {option === UserSortEnum.NameReverse && "Name (Z-A)"}
                  {option === UserSortEnum.Recent && "Recent"}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
    </div>
  )
}
