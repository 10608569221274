import { useNavigate } from "react-router-dom"
import {
  onboardingExpertisePath,
  onboardingFitWaitingPath,
} from "~/common/paths"
import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { Button } from "~/ui/button"
import { OnboardingTitle } from "~/ui/Onboarding"
import { useRef, useState } from "react"
import { Card, CardContent } from "~/ui/card"
import { CalendarDateField } from "~/ui/CalendarDateField"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { displayErrors } from "~/common/validations"
import { add, startOfDay } from "date-fns"
import { useLogEvent } from "~/analytics/EventsContext"
import {
  AhoyEventTypeEnum,
  CommunitySlug,
  OnboardingStateEnum,
  TierIntervalEnum,
  TierLevelEnum,
  UserOffer_DisplayFragment,
  UserRoleEnum,
} from "~/__generated__/graphql"
import { AlarmClock, HeartHandshake } from "lucide-react"
import { cn } from "~/lib/utils"
import { Trans, useTranslation } from "react-i18next"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import kpIntroImage from "~/images/kp-intro.png"
import annIntroImage from "~/images/ann-intro.jpeg"
import jsIntroImage from "~/images/js_portrait.jpg"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { useCommunity } from "~/community/useCommunity"
import { useUserOffers } from "~/offers/UserOffersProvider"
import { useTiers } from "~/tiers/TiersProvider"
import { OnboardingCard } from "~/components/onboarding/OnboardingCard"
import { Switch } from "~/ui/switch"
import { H3, P } from "~/ui/typography"

export const OnboardingIntroductionsScreen = () => {
  const navigate = useNavigate()
  const [snoozeDialogOpen, setSnoozeDialogOpen] = useState(false)
  const [date, setDate] = useState<Date | undefined>(undefined)
  const { logEvent } = useLogEvent()
  const { t } = useTranslation("onboarding")
  const { currentUser } = useCurrentUser()
  const canUseIntroductions = currentUser.permissions.canUseIntroductions
  const { openSubscriptionWizard } = useSubscription()
  const community = useCommunity()
  const { plusTier } = useTiers()

  const [runUserUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)

  const introUserImage: Record<CommunitySlug, string> = {
    BOARDROOM: annIntroImage,
    SAFESPACE: kpIntroImage,
    MARKETINGLAND: jsIntroImage,
  }

  const onComplete = async (snoozeUntil?: Date | null) => {
    const introductionsSnoozedUntil = snoozeUntil
      ? startOfDay(snoozeUntil).toISOString()
      : null

    const { errors } = await runUserUpdate({
      variables: {
        input: {
          introductionsSnoozedUntil,
          onboardingState: OnboardingStateEnum.FitWaiting,
        },
      },
    })
    if (errors) {
      displayErrors(errors)
    } else {
      if (introductionsSnoozedUntil) {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "SNOOZED",
        })
      } else {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "ACTIVE",
        })
      }
    }
    navigate(onboardingFitWaitingPath.pattern)
  }

  const { getBestUserOffer } = useUserOffers()
  const seeUpgradeOptions = () => {
    let bestOffer: UserOffer_DisplayFragment | null = null

    if (
      currentUser.tier?.level === TierLevelEnum.Free &&
      plusTier?.permissions.canUseIntroductions
    ) {
      bestOffer =
        bestOffer ||
        getBestUserOffer(TierLevelEnum.Plus, TierIntervalEnum.Quarter)
      bestOffer =
        bestOffer || getBestUserOffer(TierLevelEnum.Plus, TierIntervalEnum.Year)
    }

    if (currentUser.tier?.level !== TierLevelEnum.Pro) {
      bestOffer =
        bestOffer ||
        getBestUserOffer(TierLevelEnum.Pro, TierIntervalEnum.Quarter)
      bestOffer =
        bestOffer || getBestUserOffer(TierLevelEnum.Pro, TierIntervalEnum.Year)
    }

    if (bestOffer) {
      openSubscriptionWizard("SpecialOfferStep", {
        offer: bestOffer.offer,
        source: "OnboardingIntroductionsScreen",
      })
    } else {
      openSubscriptionWizard("PricingTableStep", {
        source: "OnboardingInstructionsScreen",
        requiredFeature: "canUseIntroductions",
      })
    }
  }

  const snoozeUntilRef = useRef<HTMLDivElement>(null)

  return (
    <OnboardingLayout
      step={3}
      hideCommunityFooter
      fullWidth
      containerInnerClass="max-w-[750px]"
    >
      <OnboardingTitle>
        <Trans i18nKey="introductionsScreen.title" ns="onboarding" />
      </OnboardingTitle>

      <div className="text-center mb-10">
        <Trans i18nKey="introductionsScreen.subtitle" ns="onboarding" />

        {currentUser.tier?.level === TierLevelEnum.Free &&
          " Get introductions by upgrading to a premium membership today."}
      </div>

      <div className="flex items-start justify-center flex-wrap md:flex-nowrap w-[100%]">
        <OnboardingCard user={currentUser} inlined withFakeMessageButton />
        <div className="text-[44px] md:mx-4 my-2 w-full md:w-[unset] flex justify-center self-center">
          🤝
        </div>
        <OnboardingCard
          inlined
          withFakeMessageButton
          formData={{
            id: "intro-user",
            firstName: t("introductionsScreen.introFirstName"),
            lastName: t("introductionsScreen.introLastName"),
            expertise: [
              t("introductionsScreen.introExpertise.0"),
              t("introductionsScreen.introExpertise.1"),
              t("introductionsScreen.introExpertise.2"),
            ],
            interests: [
              t("introductionsScreen.introInterests.0"),
              t("introductionsScreen.introInterests.1"),
              t("introductionsScreen.introInterests.2"),
            ],
            photoUrl: introUserImage[community.slug],
            companyName: t("introductionsScreen.introCompany"),
            jobTitle: t("introductionsScreen.introTitle"),
            location: t("introductionsScreen.introLocation"),
            role: UserRoleEnum.Member,
          }}
        />
      </div>

      <div className="flex flex-col items-center gap-4 border-t border-pretext-light-gray pt-8 border-opacity-30 mt-8">
        {canUseIntroductions ? (
          <>
            <div className="font-medium">Introductions Settings:</div>
            <Switch
              checked={!snoozeDialogOpen}
              onClick={() => {
                setSnoozeDialogOpen((prev) => {
                  const willOpen = !prev
                  if (willOpen) {
                    setTimeout(
                      () =>
                        window.scrollTo({
                          left: 0,
                          top: snoozeUntilRef.current?.offsetTop,
                          behavior: "smooth",
                        }),
                      100
                    )
                  }
                  return willOpen
                })
              }}
              variant="selector"
              size="xl"
              options={[
                <div className="flex items-center justify-center gap-1">
                  <AlarmClock className="w-4 h-4" />
                  Snooze
                </div>,
                <div className="flex items-center justify-center gap-1">
                  <HeartHandshake className="w-4 h-4" />
                  Participate
                </div>,
              ]}
            />

            {!snoozeDialogOpen && (
              <>
                <Button
                  type="button"
                  variant="onboarding"
                  className={cn("py-2 px-8 max-w-128 w-full mt-8")}
                  disabled={loading}
                  onClick={() => onComplete()}
                >
                  Continue
                </Button>
              </>
            )}

            {snoozeDialogOpen && (
              <>
                <Card className="px-6 py-8" ref={snoozeUntilRef}>
                  <CardContent className="text-center flex flex-col gap-4">
                    <H3>Not ready for intros? No problem.</H3>
                    <P className="text-sm">
                      You can pause introductions for now. We’ll hold on
                      introducing you to peers until after your snooze date. You
                      can adjust this in your settings later anytime
                    </P>
                    <div className="flex flex-col w-full text-left mx-auto md:max-w-128">
                      <div>Snooze until:</div>
                      <div className="flex items-center w-full gap-2">
                        <div className="flex-grow">
                          <CalendarDateField
                            date={date}
                            onChangeDate={setDate}
                            fromYear={new Date().getFullYear()}
                            toYear={new Date().getFullYear() + 2}
                            disabled={{
                              before: new Date(),
                              after: add(new Date(), { years: 2 }),
                            }}
                          />
                        </div>
                        <Button
                          type="button"
                          variant="onboarding"
                          className={cn("py-2 px-8")}
                          disabled={!date || loading}
                          onClick={() => onComplete(date)}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <div className="flex justify-center mt-4"></div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="flex flex-col items-center">
              <Button
                variant="onboarding"
                type="button"
                onClick={seeUpgradeOptions}
                className="min-w-[400px] disabled:opacity-100 py-4 text-base"
              >
                Upgrade Today
              </Button>
            </div>
            <Button
              variant="link"
              className="onboarding-footer-link"
              onClick={() => onComplete()}
            >
              No thanks, I'll stick to the free tier
            </Button>
          </>
        )}

        <div className="mx-auto">
          <Button
            variant="link"
            className="text-xs mx-auto"
            onClick={() => navigate(onboardingExpertisePath.pattern)}
          >
            Back
          </Button>
        </div>
      </div>
    </OnboardingLayout>
  )
}
