import { useCallback, useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { RulesEngineEventTypeEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { feedPath } from "~/common/paths"
import { Battery } from "~/components/Battery"
import { useRulesEngine } from "~/rules-engine/RulesEngineContext"
import { Card, CardContent } from "~/ui/card"
import { H1, Muted } from "~/ui/typography"
import Brain from "~/images/icons/brain.svg?react"

const intentEvents = [
  "keydown",
  "click",
  "scroll",
  "wheel",
  "mousemove",
  "touchstart",
]

export const AskTheBotScreen = () => {
  const [, setMostRecentActivity] = useState<number>(Date.now())
  const [mostRecentEventFiredAt, setMostRecentEventFiredAt] = useState<number>(
    Date.now()
  )
  const isFiringRef = useRef<boolean>(false)
  const { createRulesEngineEvent } = useRulesEngine()
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation("bot")

  const onUserActivity = useCallback(() => {
    setMostRecentActivity((mostRecentActivity) => {
      if (
        mostRecentEventFiredAt < mostRecentActivity - 10000 &&
        !isFiringRef.current
      ) {
        isFiringRef.current = true
        createRulesEngineEvent(RulesEngineEventTypeEnum.UserIsActiveOnBot)
        setMostRecentEventFiredAt(Date.now())
      }
      return Date.now()
    })
  }, [
    setMostRecentActivity,
    createRulesEngineEvent,
    setMostRecentEventFiredAt,
    mostRecentEventFiredAt,
  ])

  useEffect(() => {
    isFiringRef.current = false
  }, [mostRecentEventFiredAt])

  useEffect(() => {
    intentEvents.forEach((event) => {
      window.addEventListener(event, onUserActivity)
    })

    return () => {
      intentEvents.forEach((event) => {
        window.removeEventListener(event, onUserActivity)
      })
    }
  }, [onUserActivity])

  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser.botBatteryBalance <= 0) {
      toast.success("You have run out of battery! Please come back later.")
      navigate(feedPath.pattern)
    }
  })

  if (currentUser.botBatteryBalance <= 0) return null

  return (
    <div className="flex flex-col gap-4">
      <Card>
        <CardContent className="p-0">
          <div className="flex items-center divide-x">
            <div className="flex items-center gap-4 flex-grow px-8">
              <div>
                <Brain className="w-10 h-10" />
              </div>
              <div className="flex flex-col gap-1">
                <H1 variant="h3" className="text-2xl">
                  {t("headerTitle")}
                </H1>
                <Muted>{t("headerSubtitle")}</Muted>
              </div>
            </div>
            <div className="flex items-center gap-4 p-8">
              <Battery
                level={currentUser.botBatteryBalance}
                className="w-8 h-8"
              />
              <div className="text-2xl">
                {Math.ceil(currentUser.botBatteryBalance)}%
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="rounded-2xl overflow-hidden">
        <iframe
          title={t("iframeTitle")}
          src="https://www.delphi.ai/web_embed/490a10cd-cb06-4ba7-8bb4-e4a2f9f6d372"
          width="100%"
          height="810px"
          allow="microphone"
        ></iframe>
      </div>
    </div>
  )
}
