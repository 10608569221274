import { Dialog, DialogContent } from "~/ui/dialog"
import { Button } from "~/ui/button"
import { useLazyQuery } from "@apollo/client"
import AsyncSelect from "react-select/async"
import { useCallback, useEffect } from "react"
import { MENTION_USER_QUERY_DOCUMENT } from "~/post-composer/useMentionDataSource"
import { useDebouncedCallback } from "use-debounce"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { UserPill } from "~/ui/UserPill"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { roomPath } from "~/common/paths"
import invariant from "tiny-invariant"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum, UserRoleEnum } from "~/__generated__/graphql"

export const MAX_GROUP_MEMBERS = 20
const MAX_INVITABLE = MAX_GROUP_MEMBERS - 1

type UserOption = {
  value: string
  label: string
  data: {
    id: string
    name: string
    firstName: string
    lastName: string
    photoUrl?: string
    role: UserRoleEnum
  }
}

const formSchema = z.object({
  name: z.string(),
  selectedUsers: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        photoUrl: z.string().optional(),
        role: z.nativeEnum(UserRoleEnum),
      })
    )
    .min(1, { message: "Please select at least 1 person" })
    .max(MAX_INVITABLE, {
      message: `You may only invite a max of ${MAX_INVITABLE} people`,
    }),
})

export type FormValues = z.infer<typeof formSchema>

export const StartRoomModal = ({
  isOpen,
  setIsOpen,
  onSuccess,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  onSuccess: () => void
}) => {
  const { logEvent } = useLogEvent()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      selectedUsers: [],
    },
  })
  const navigate = useNavigate()

  const [getCollaboratorSearch] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: { excludeSelf: true },
  })

  const [runMutation, mutationResult] = useSafeMutation(ROOM_CREATE_MUTATION)

  const onSubmit = async (values: FormValues) => {
    const { data, errors } = await runMutation({
      variables: {
        input: {
          name: values.name,
          userIds: values.selectedUsers.map((u) => u.id),
        },
      },
    })
    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      invariant(data)
      toast.success("Group created")
      setIsOpen(false)
      logEvent(AhoyEventTypeEnum.DirectMessageInitiated, {
        user_ids: data.roomCreate.room.users.map(({ id }) => id).join(","),
        room_id: data.roomCreate.room.id,
        room_type: data.roomCreate.room.roomType,
      })
      navigate(roomPath({ roomId: data.roomCreate.room.id }))
      onSuccess()
    }
  }

  useEffect(() => {
    if (isOpen === false) form.reset()
  }, [isOpen, form])

  const _loadOptions = useCallback(
    (query: string, callback: (options: UserOption[]) => void) => {
      getCollaboratorSearch({
        variables: { query },
      }).then(({ data }) => {
        if (!data) {
          callback([])
        } else {
          callback(
            data.users.nodes.map((u) => ({
              value: u.id,
              label: u.name || "",
              data: {
                id: u.id,
                name: u.name || "",
                firstName: u.firstName || "",
                lastName: u.lastName || "",
                photoUrl: u.photoUrl || undefined,
                role: u.role || UserRoleEnum.Member,
              },
            }))
          )
        }
      })
    },
    [getCollaboratorSearch]
  )
  const loadOptions = useDebouncedCallback(_loadOptions, 400)

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="mb-6 text-center font-serif text-xl">
          Create a Group
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col mb-4 gap-6">
              <FormField
                control={form.control}
                name="selectedUsers"
                render={() => (
                  <FormItem>
                    <FormLabel required className="block">
                      Search members
                    </FormLabel>
                    <FormControl>
                      <AsyncSelect
                        classNamePrefix="select"
                        className="flex-1"
                        placeholder="Who are you messaging..."
                        loadOptions={loadOptions}
                        value={null}
                        onChange={(selection) => {
                          if (selection?.value) {
                            form.setValue("selectedUsers", [
                              selection.data,
                              ...form.getValues()["selectedUsers"],
                            ])
                          }
                        }}
                        styles={{
                          control: (baseStyles, _state) => ({
                            ...baseStyles,
                            outline: "none !important",
                            boxShadow: "none",
                            paddingTop: "0.25rem",
                            paddingBottom: "0.25rem",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            fontSize: 16,
                            borderRadius: 8,
                          }),
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex flex-wrap gap-3">
                {form.watch("selectedUsers").map((user) => (
                  <UserPill
                    user={user}
                    key={user.id}
                    canRemove
                    onRemove={() => {
                      form.setValue(
                        "selectedUsers",
                        form
                          .getValues("selectedUsers")
                          .filter((item) => item.id !== user.id)
                      )
                    }}
                  />
                ))}
              </div>

              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="block">
                      Group name (optional)
                    </FormLabel>
                    <FormControl>
                      <input
                        type="text"
                        className="bg-white w-full border-mercury rounded-lg py-2 px-4"
                        placeholder="Friday Happy Hour Group"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="h-[1px] bg-gray-300 my-8" />

            <div className="flex justify-end gap-4">
              <Button
                type="button"
                variant="outline"
                className="px-10"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="px-10"
                isLoading={mutationResult.loading}
                disabled={mutationResult.loading}
              >
                Create Group
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const ROOM_CREATE_MUTATION = gql(`
  mutation RoomCreateModal($input: RoomCreateInput!) {
    roomCreate(input: $input) {
      room {
        id
        roomType
        users {
          id
        }
      }
    }
  }
`)
