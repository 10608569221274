import { useMemo } from "react"
import { CurrentUserProviderQuery, UserRoleEnum } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { UserName } from "~/directory/UserName"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { FakeButton } from "~/ui/button"
import { MailOpen } from "lucide-react"

export interface OnboardingCardDataType {
  id: string
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  companyName?: string | null | undefined
  jobTitle?: string | null | undefined
  photoUrl?: string | null | undefined
  location?: string | null | undefined
  bio?: string | null | undefined
  interests?: string[] | null | undefined
  expertise?: string[] | null | undefined
  coach?: boolean
  admin?: boolean
  role: UserRoleEnum
}

export const OnboardingCard = ({
  user,
  formData,
  inlined,
  className,
  withFakeMessageButton,
}: {
  user?: NonNullable<CurrentUserProviderQuery["user"]>
  formData?: OnboardingCardDataType
  inlined?: boolean
  className?: string
  withFakeMessageButton?: boolean
}) => {
  const data = useMemo<OnboardingCardDataType>(() => {
    return Object.assign(
      user
        ? {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            companyName: user.companyName,
            jobTitle: user.jobTitle,
            location: user.place?.full,
            photoUrl: user.photoUrl,
            bio: user.bio,
            interests: user.interests.map((tag) => tag.name),
            expertise: user.expertise.map((tag) => tag.name),
            admin: !!user.admin,
            coach: !!user.coach,
            role: user.role,
          }
        : {},
      formData || {}
    ) as OnboardingCardDataType
  }, [user, formData])

  return (
    <div
      className={cn(
        "min-[1400px]:flex flex-col items-center rounded-xl bg-white text-dark-gray border border-borderColor p-6 w-[320px] min-h-[230px] tracking-[0.5px]",
        !inlined && "hidden absolute top-0 left-[calc(50%+354px)]",
        className
      )}
    >
      <AvatarWithFallback user={data} size="onboarding" />
      <div className="mt-2 font-semibold text-[15px] text-center">
        <UserName user={data} />
      </div>
      {data.jobTitle && data.jobTitle.length > 0 && (
        <div className="mt-3 text-[14px] text-center">{data.jobTitle}</div>
      )}
      {(data.companyName?.length || data.location?.length) && (
        <div className="mt-1 text-[12px] text-[#999999] text-center">
          {data.companyName}
          {data.companyName?.length && data.location?.length ? " - " : ""}
          {data.location}
        </div>
      )}
      {data.bio && <div className="mt-6 leading-[22px]">{data.bio}</div>}
      {data.interests && data.interests.length > 0 && (
        <div className="onboarding-card-tag-section">
          EXCITED ABOUT
          <div className="onboarding-card-tags">
            {data.interests.map((s) => (
              <div className="onboarding-card-tag" key={`interest-${s}`}>
                {s}
              </div>
            ))}
          </div>
        </div>
      )}
      {data.expertise && data.expertise.length > 0 && (
        <div className="onboarding-card-tag-section">
          EXPERTISE
          <div className="onboarding-card-tags">
            {data.expertise.map((s) => (
              <div className="onboarding-card-tag" key={`expertise-${s}`}>
                {s}
              </div>
            ))}
          </div>
        </div>
      )}
      {withFakeMessageButton && (
        <FakeButton className="text-2xs mt-auto w-full mt-8">
          <MailOpen className="mr-2 w-4 h-4" />
          Message
        </FakeButton>
      )}
    </div>
  )
}
