import { useQuery } from "@apollo/client"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { MessagesPane } from "~/chat/MessagesPane"
import { RoomsPane } from "~/chat/RoomsPane"
import { StartDmModal } from "~/chat/StartDmModal"
import { useMessagesSubscription } from "~/chat/useMessagesSubscription"
import { Card } from "~/ui/card"
import SmsNotification from "../images/icons/sms-notification.svg?react"
import { Button } from "~/ui/button"
import { cn } from "~/lib/utils"
import { Lock, Menu } from "lucide-react"
import { dmPath, messagesPath, roomPath } from "~/common/paths"
import { parseISO } from "date-fns"
import { StartRoomModal } from "~/chat/StartRoomModal"
import { useCommunity } from "~/community/useCommunity"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

const ROOMS_PER_PAGE = 20

export const MessagesScreen = () => {
  const [startDmModalOpen, setStartDmModalOpen] = useState(false)
  const [startRoomModalOpen, setStartRoomModalOpen] = useState(false)
  const { otherUserId, roomId } = useParams()
  const { currentUser } = useCurrentUser()
  const { openSubscriptionWizard } = useSubscription()
  const messagesScrollViewRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const { logEvent } = useLogEvent()
  const roomsResult = useQuery(MY_ROOMS_QUERY_DOCUMENT, {
    variables: {
      first: ROOMS_PER_PAGE,
    },
  })
  const rooms = useMemo(
    () => roomsResult.data?.myRooms.edges.map((e) => e.node) || [],
    [roomsResult.data]
  )
  const orderedRooms = useMemo(
    () =>
      rooms.slice().sort((a, b) => {
        const aDate = parseISO(a.latestPost?.createdAt || a.createdAt)
        const bDate = parseISO(b.latestPost?.createdAt || b.createdAt)
        return bDate.getTime() - aDate.getTime()
      }),
    [rooms]
  )

  const navigate = useNavigate()
  const community = useCommunity()

  const [mobileConversationsOpen, setMobileConversationsOpen] = useState(false)

  useMessagesSubscription({
    scrollviewRef: messagesScrollViewRef,
    visibleRoomIds: roomsResult.data?.myRooms.edges.map((e) => e.node.id) || [],
    refetchRooms: roomsResult.refetch,
  })

  useEffect(() => {
    if (otherUserId && otherUserId === currentUser.id && !currentUser.admin) {
      navigate(messagesPath.pattern)
    }
  }, [otherUserId, currentUser.id, navigate, currentUser.admin])

  useEffect(() => {
    if (location.pathname !== messagesPath.pattern) return

    const firstRoom = orderedRooms[0]

    if (firstRoom?.dmOtherUser?.id) {
      navigate(dmPath({ otherUserId: firstRoom.dmOtherUser.id }))
    } else if (firstRoom?.id) {
      navigate(roomPath({ roomId: firstRoom.id }))
    }
  }, [orderedRooms, navigate, location])

  const initiateDirectMessage = useCallback(() => {
    logEvent(AhoyEventTypeEnum.DirectMessageInitiated)

    if (currentUser.permissions.canInitiateDms) {
      setStartDmModalOpen(true)
    } else {
      openSubscriptionWizard("PricingTableStep", {
        source: "InitialDirectMessage",
        requiredFeature: "canInitiateDms",
      })
    }
  }, [currentUser, openSubscriptionWizard, logEvent])

  const initiateNewRoom = useCallback(() => {
    if (currentUser.permissions.canInitiateDms) {
      setStartRoomModalOpen(true)
    } else {
      openSubscriptionWizard("PricingTableStep", {
        source: "InitiateDirectMessage",
        requiredFeature: "canInitiateDms",
      })
    }
  }, [currentUser, openSubscriptionWizard])

  return (
    <>
      <Card
        className={"flex overflow-hidden xl:pl-[300px] h-[calc(100vh-118px)]"}
      >
        <div
          className={cn(
            "border-r border-mercury w-[300px] flex flex-col absolute left-0 xl:left-0 top-0 bottom-0 bg-white z-[20] transition-[left] ease-in xl:transition-none",
            {
              "-left-[300px]": !mobileConversationsOpen,
            }
          )}
        >
          <RoomsPane
            openComposeModal={initiateDirectMessage}
            openNewRoomModal={initiateNewRoom}
            roomsResult={roomsResult}
            orderedRooms={orderedRooms}
            setMobileConversationsOpen={setMobileConversationsOpen}
          />
        </div>
        <div className="flex flex-col flex-1">
          {roomId || otherUserId ? (
            <MessagesPane
              dmOtherUserId={otherUserId}
              roomId={roomId}
              scrollviewRef={messagesScrollViewRef}
              setMobileConversationsOpen={setMobileConversationsOpen}
              initiateDirectMessage={initiateDirectMessage}
            />
          ) : (
            <div className="flex flex-col items-center pt-12 relative">
              <div
                onClick={() => setMobileConversationsOpen(true)}
                className="mr-4 xl:hidden cursor-pointer top-6 left-6 absolute"
              >
                <Menu />
              </div>
              <div className="text-primary rounded-full border-2 border-primary flex items-center justify-center p-4 mb-4">
                <SmsNotification />
              </div>
              <div className="text-lg font-semibold mb-2">Your Messages</div>
              <div className="text-sm mb-6 tracking-wide">
                Start a private conversation with a {community.name} member!
              </div>
              <Button
                onClick={initiateDirectMessage}
                type="button"
                className="flex gap-2 items-center"
              >
                Compose Message
                {currentUser.permissions.canInitiateDms ? null : (
                  <Lock className="w-4 h-4" />
                )}
              </Button>
            </div>
          )}
        </div>
      </Card>
      <StartDmModal isOpen={startDmModalOpen} setIsOpen={setStartDmModalOpen} />
      <StartRoomModal
        isOpen={startRoomModalOpen}
        setIsOpen={setStartRoomModalOpen}
        onSuccess={() => roomsResult.refetch()}
      />
    </>
  )
}

export const MY_ROOMS_QUERY_DOCUMENT = gql(`
  query MyRooms($first: Int, $roomsCursor: String) {
    myRooms(first: $first, after: $roomsCursor) {
      edges {
        node {
          ...Room_MessageList
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)

gql(`
  fragment Room_MessageList on Room {
    id
    createdAt
    name
    defaultName
    memberCount
    latestPost {
      id
      createdAt
      user {
        id
      }
    }
    hasUnreadPosts
    dmOtherUser {
      id
      name
      ...User_Avatar
    }
  }
`)
