import { DotsHorizontalIcon, EyeNoneIcon } from "@radix-ui/react-icons"
import { formatDate, parseISO } from "date-fns"
import { useCallback, useEffect, useRef, useState } from "react"
import {
  User_AdminFragment,
  UserSortEnum,
  UserRoleEnum,
  UserUpdateInput,
} from "~/__generated__/graphql"
import { UserDialog } from "~/directory/UserDialog"
import { Button } from "~/ui/button"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import { TableCell, TableRow } from "~/ui/table"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { ChangeEmailModal } from "~/admin/ChangeEmailModal"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_ADMIN_UPDATE_MUTATION } from "~/screens/admin/AdminMembersScreen"
import { displayErrors } from "~/common/validations"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"
import {
  AdminTableHeader,
  UsersTable,
  UsersTableHandle,
} from "~/admin/users/UsersTable"
import { UserName } from "~/directory/UserName"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import { AdminHeader } from "~/admin/AdminHeader"
import { Eye, EyeOff, UserCircle } from "lucide-react"
import { Switch } from "~/ui/switch"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/ui/dialog"
import { DialogProps } from "@radix-ui/react-dialog"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "~/ui/form"
import { TextField } from "~/components/forms/TextField"
import { ToggleField } from "~/components/forms/ToggleField"
import { gql } from "~/__generated__"
import toast from "react-hot-toast"
import { useConfirm } from "~/ui/Confirm"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useTiers } from "~/tiers/TiersProvider"

const HEADERS: AdminTableHeader[] = [
  { label: "Name", isPinned: true },
  { label: "Invite Status" },
  { label: "Invited On" },
  { label: "Onboarded On" },
  { label: "Visibility" },
  { label: "Actions" },
]

const pinnedColumnStyles =
  "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent"

export const AdminAdministratorsScreen = () => {
  const { currentUser } = useCurrentUser()
  const [isAddAdminDialogOpen, setIsAddAdminDialogOpen] = useState(false)
  const usersTableRef = useRef<UsersTableHandle | null>(null)

  const onAdminCreated = useCallback(() => {
    usersTableRef.current?.refetch()
  }, [usersTableRef])

  return (
    <>
      <AdminHeader title="Administrators" Icon={UserCircle}>
        {currentUser.currentUserCanCreateAdmin.value && (
          <>
            <Button onClick={() => setIsAddAdminDialogOpen(true)} type="button">
              Add Admin
            </Button>
            <AddAdminDialog
              open={isAddAdminDialogOpen}
              onOpenChange={setIsAddAdminDialogOpen}
              onAdminCreated={onAdminCreated}
            />
          </>
        )}
      </AdminHeader>
      <UsersTable
        defaultSort={UserSortEnum.Recent}
        headers={HEADERS}
        queryOptions={{ roles: [UserRoleEnum.Admin, UserRoleEnum.Superadmin] }}
        ref={usersTableRef}
      >
        {(user) => (
          <UserRow
            key={user.id}
            user={user}
            refetch={usersTableRef.current?.refetch}
          />
        )}
      </UsersTable>
    </>
  )
}

const UserRow = ({
  user,
  refetch,
}: {
  user: User_AdminFragment
  refetch?: UsersTableHandle["refetch"]
}) => {
  const { proTier } = useTiers()
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false)
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const [runUserUpdate] = useSafeMutation(USER_ADMIN_UPDATE_MUTATION)

  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)

  const toggleHidden = async () => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          userId: user.id,
          hidden: !user.hidden,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast.success(user.hidden ? "Unhidden" : "Hidden")
    }
  }

  const toggleCoach = async () => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          userId: user.id,
          coach: !user.coach,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast.success(user.coach ? "Unmarked as Coach" : "Marked as Coach")
    }
  }

  const showConfirm = useConfirm()

  const changeRole = async (role: UserRoleEnum) => {
    const input: UserUpdateInput = {
      userId: user.id,
      role,
    }

    if (role === UserRoleEnum.Member) {
      input.tierId = proTier?.id
    }

    const { errors } = await runUserUpdate({ variables: { input } })

    if (errors) {
      displayErrors(errors)
    } else {
      if (role === UserRoleEnum.Member && refetch) refetch()
      toast.success("Role updated")
    }
  }

  const setAsMember = async () => {
    await showConfirm({
      title: "Remove Admin Status?",
      body: `Taking this action will remove admin status from this user. The user will still retain their Pro-Annual Subscription and access to the platform.`,
      confirmText: "Remove Admin Status",
      onConfirm: () => changeRole(UserRoleEnum.Member),
    })
  }

  const setAsAdmin = async () => {
    await showConfirm({
      title: "Change Role to Admin?",
      body: `Are you sure you want to change ${user.name}'s role to Admin?`,
      onConfirm: () => changeRole(UserRoleEnum.Admin),
    })
  }

  const setAsSuperadmin = async () => {
    await showConfirm({
      title: "Change Role to Super Admin?",
      body: `Are you sure you want to change ${user.name}'s role to Super Admin?`,
      onConfirm: () => changeRole(UserRoleEnum.Superadmin),
    })
  }

  const deactivate = async () => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          userId: user.id,
          role: UserRoleEnum.Member,
          active: false,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      refetch?.()
      toast.success("User deactivated")
    }
  }

  const deactivateUser = async () => {
    await showConfirm({
      title: "Deactivate Member's account?",
      body: (
        <>
          <p className="mb-4">
            This action will remove this user as an admin and cancel this user's
            subscription to the membership. They will lose all admin controls
            and will no longer be able to access the app.
          </p>
          <p>This action is non reversible.</p>
        </>
      ),
      confirmText: "Deactive User",
      onConfirm: () => deactivate(),
    })
  }

  const [runResendInvite] = useSafeMutation(USER_RESEND_INVITE_MUTATION)
  const resendInvite = async () => {
    const { errors } = await runResendInvite({
      variables: {
        input: {
          userId: user.id,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast.success("Invitation resent")
    }
  }

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  const { currentUser } = useCurrentUser()

  return (
    <>
      {changeEmailModalOpen && (
        <ChangeEmailModal
          userId={user.id}
          email={user.email || ""}
          isOpen={changeEmailModalOpen}
          setIsOpen={setChangeEmailModalOpen}
          name={user.name}
        />
      )}

      {userDialogOpen && (
        <UserDialog
          isOpen={userDialogOpen}
          onClose={() => setUserDialogOpen(false)}
          user={user}
        />
      )}

      <ContextMenu>
        <ContextMenuContent>
          {!user.onboarded && (
            <ContextMenuItem onClick={resendInvite} className="cursor-pointer">
              Resend Invite
            </ContextMenuItem>
          )}
          <ContextMenuItem onClick={toggleCoach} className="cursor-pointer">
            {user.coach ? "Unmark as Coach" : "Mark as Coach"}
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => setChangeEmailModalOpen(true)}
            className="cursor-pointer"
          >
            Change Email
          </ContextMenuItem>
          {currentUser.role === UserRoleEnum.Superadmin && (
            <>
              {user.role === UserRoleEnum.Superadmin && (
                <ContextMenuItem
                  onClick={setAsAdmin}
                  className="cursor-pointer"
                >
                  Demote to Admin
                </ContextMenuItem>
              )}
              {user.role === UserRoleEnum.Admin && (
                <ContextMenuItem
                  onClick={setAsSuperadmin}
                  className="cursor-pointer"
                >
                  Promote to Super Admin
                </ContextMenuItem>
              )}
              <ContextMenuItem onClick={setAsMember} className="cursor-pointer">
                Revoke Admin Access
              </ContextMenuItem>
              <ContextMenuItem
                onClick={deactivateUser}
                className="cursor-pointer"
              >
                Deactivate User
              </ContextMenuItem>
            </>
          )}
        </ContextMenuContent>
        <ContextMenuTrigger asChild>
          <TableRow ref={contextMenuTriggerRef} className="group">
            <TableCell className={pinnedColumnStyles}>
              <div className="flex gap-2">
                <div className="w-[30px] h-[30px]">
                  <AvatarWithFallback user={user} size="post-autocomplete" />
                </div>
                <div className="flex flex-col gap-1 items-start">
                  <div className="flex gap-2 items-center">
                    <Button
                      variant="link"
                      size="inline"
                      className="text-primary"
                      onClick={() => setUserDialogOpen(true)}
                    >
                      <UserName user={user} />
                    </Button>
                    {user.hidden && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <EyeNoneIcon className="text-pretext-grey" />
                          </TooltipTrigger>
                          <TooltipContent>
                            User is hidden from the directory
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                  {user.email && (
                    <CopyToClipboard
                      className="text-2xs text-gray-500"
                      text={user.email}
                    >
                      <div className="truncate max-w-[140px]">{user.email}</div>
                    </CopyToClipboard>
                  )}
                </div>
              </div>
            </TableCell>

            <TableCell>{user.onboarded ? "Onboarded" : "Invited"}</TableCell>

            <TableCell>
              {user.createdAt &&
                formatDate(parseISO(user.createdAt), "MMM d, yyyy")}
            </TableCell>

            <TableCell>
              {user.onboardedAt &&
                formatDate(parseISO(user.onboardedAt), "MMM d, yyyy")}
            </TableCell>

            <TableCell>
              <SimpleTooltip
                content={user.hidden ? "Hidden" : "Visible"}
                delayDuration={500}
                cursor="pointer"
              >
                <div className="inline-block">
                  <Switch
                    checked={!user.hidden}
                    onClick={toggleHidden}
                    variant="selector"
                    options={[
                      <EyeOff size={16} className="mx-4" />,
                      <Eye size={16} className="mx-4" />,
                    ]}
                  />
                </div>
              </SimpleTooltip>
            </TableCell>

            <TableCell>
              <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
                <DotsHorizontalIcon />
              </Button>
            </TableCell>
          </TableRow>
        </ContextMenuTrigger>
      </ContextMenu>
    </>
  )
}

const addAdminFormSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email().min(1),
  superadmin: z.boolean(),
})
type AddAdminFormValues = z.infer<typeof addAdminFormSchema>

interface AddAdminDialogProps extends DialogProps {
  onAdminCreated?: () => void
}
const AddAdminDialog = (props: AddAdminDialogProps) => {
  const form = useForm<AddAdminFormValues>({
    resolver: zodResolver(addAdminFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      superadmin: false,
    },
  })

  useEffect(() => {
    if (!props.open) {
      form.reset()
    }
  }, [props.open, form])

  const [runCreateUser] = useSafeMutation(CREATE_USER_MUTATION)

  const onSubmit = form.handleSubmit(async (values) => {
    const { errors } = await runCreateUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: values.superadmin
            ? UserRoleEnum.Superadmin
            : UserRoleEnum.Admin,
          fit: true,
          hidden: true,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      props.onAdminCreated?.()
      props.onOpenChange?.(false)
      toast.success(`Invitation sent to ${values.email}`)
    }
  })

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Invite Admin</DialogTitle>
          <DialogDescription>
            Invite an admin to the platform.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={onSubmit}>
            <div className="flex flex-col gap-4">
              <TextField
                label="First Name"
                control={form.control}
                name="firstName"
                required
              />
              <TextField
                label="Last Name"
                control={form.control}
                name="lastName"
                required
              />
              <TextField
                label="Email"
                control={form.control}
                name="email"
                required
              />
              <ToggleField
                label="Add as Super Admin?"
                control={form.control}
                name="superadmin"
              />
            </div>

            <DialogFooter className="mt-4 justify-end">
              <Button
                variant="ghost"
                onClick={() => props.onOpenChange?.(false)}
              >
                Cancel
              </Button>
              <Button type="submit" size="lg">
                Invite Admin
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export const CREATE_USER_MUTATION = gql(`
  mutation UserCreate($input: UserCreateInput!) {
    userCreate(input: $input) {
      user {
        id
        firstName
        lastName
        email
        superadmin
      }
    }
  }
`)

export const USER_RESEND_INVITE_MUTATION = gql(`
  mutation UserResendInvite($input: UserResendInviteInput!) {
    userResendInvite(input: $input) {
      user {
        id
        firstName
        lastName
        email
        superadmin
      }
    }
  }
`)
