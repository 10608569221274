import { Check, ChevronsUpDown, Plus, Trash } from "lucide-react"
import { Dispatch, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Place_FilterFragment,
  RulesEngineCondition_NestableFragment,
  RulesEngineConditionOperatorEnum,
} from "~/__generated__/graphql"
import { useTags } from "~/common/queries"
import { cn } from "~/lib/utils"
import { useTiers } from "~/tiers/TiersProvider"
import { Button } from "~/ui/button"
import { Command, CommandGroup, CommandInput, CommandItem } from "~/ui/command"
import { IconButton } from "~/ui/IconButton"
import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { PlaceFilter } from "./users/UsersFilters"
import { gql, useQuery } from "@apollo/client"

interface RulesEngineConditionsBuilderProps {
  initialConditions?: RulesEngineCondition_NestableFragment
  onChange: (conditions: RulesEngineCondition_NestableFragment | null) => void
}

export const RulesEngineConditionsBuilder = ({
  initialConditions,
  onChange,
}: RulesEngineConditionsBuilderProps) => {
  const [conditions, setConditions] =
    useState<RulesEngineCondition_NestableFragment>(
      initialConditions || {
        all: [],
      }
    )
  const deleteCondition = useCallback((index: number) => {}, [])

  useEffect(() => {
    onChange(conditions)
  }, [conditions, onChange])

  return (
    <RulesEngineConditionBuilderRow
      condition={conditions}
      setCondition={setConditions}
      deleteCondition={deleteCondition}
      isRoot
    />
  )
}

const AnyAllSelect = ({
  value,
  onValueChange,
}: {
  value: "any" | "all"
  onValueChange: (value: "any" | "all") => void
}) => {
  return (
    <Select
      value={value}
      onValueChange={(value) => onValueChange(value as "any" | "all")}
    >
      <SelectTrigger inline>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="any">Any</SelectItem>
        <SelectItem value="all">All</SelectItem>
      </SelectContent>
    </Select>
  )
}

type Fact =
  | "user.tier.level"
  | "user.expertise_tags"
  | "user.interest_tags"
  | "user.fit_profile.industry"
  | "user.fit_profile.job_function"
  | "user.fit_profile.vertical"
  | "user.place"

const factOperators: Record<Fact, RulesEngineConditionOperatorEnum[]> = {
  "user.tier.level": [
    RulesEngineConditionOperatorEnum.Equal,
    RulesEngineConditionOperatorEnum.NotEqual,
  ],
  "user.expertise_tags": [
    RulesEngineConditionOperatorEnum.Includes,
    RulesEngineConditionOperatorEnum.Excludes,
  ],
  "user.interest_tags": [
    RulesEngineConditionOperatorEnum.Includes,
    RulesEngineConditionOperatorEnum.Excludes,
  ],
  "user.fit_profile.industry": [
    RulesEngineConditionOperatorEnum.Equal,
    RulesEngineConditionOperatorEnum.NotEqual,
  ],
  "user.fit_profile.job_function": [
    RulesEngineConditionOperatorEnum.Equal,
    RulesEngineConditionOperatorEnum.NotEqual,
  ],
  "user.fit_profile.vertical": [
    RulesEngineConditionOperatorEnum.Equal,
    RulesEngineConditionOperatorEnum.NotEqual,
  ],
  "user.place": [
    RulesEngineConditionOperatorEnum.LocatedWithin,
    RulesEngineConditionOperatorEnum.NotLocatedWithin,
  ],
}

type Option = {
  value: string
  label: string
}

const useFactOptions = () => {
  const tags = useTags()
  const { tiers } = useTiers()

  const { data, loading } = useQuery(FACT_OPTIONS_QUERY_DOCUMENT)

  if (loading) return {} as Record<Fact, Option[]>

  return {
    "user.tier.level":
      tiers?.map((tier) => ({
        value: tier.level.toLowerCase(),
        label: tier.name,
      })) || [],
    "user.expertise_tags":
      tags?.map((tag) => ({ value: tag.id, label: tag.name })) || [],
    "user.interest_tags":
      tags?.map((tag) => ({ value: tag.id, label: tag.name })) || [],
    "user.fit_profile.industry": data.industries,
    "user.fit_profile.job_function": data.jobFunctions,
    "user.fit_profile.vertical": data.verticals,
  } as Record<Fact, Option[]>
}

const FACT_OPTIONS_QUERY_DOCUMENT = gql(`
  query FactOptions {
    industries { value label }
    jobFunctions { value label }
    verticals { value label }
  }
`)

const RulesEngineConditionBuilderRow = ({
  index = 0,
  isAll: isParentAll,
  isRoot,
  condition,
  setCondition,
  deleteCondition,
}: {
  index?: number
  isAny?: boolean
  isAll?: boolean
  isRoot?: boolean
  condition: RulesEngineCondition_NestableFragment
  setCondition: Dispatch<RulesEngineCondition_NestableFragment>
  deleteCondition: (index: number) => void
}) => {
  const { t } = useTranslation("rulesEngine")
  const isAny = Object.keys(condition).includes("any")
  const isAll = Object.keys(condition).includes("all")
  const items = isAny ? condition.any! : isAll ? condition.all! : []
  const factOptions = useFactOptions()
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState("")

  const placeFilter = useMemo(() => {
    if (condition.fact !== "user.place") return undefined
    return condition.valueString ? { id: condition.valueString } : undefined
  }, [condition.fact, condition.valueString])

  const setPlaceFilter = useCallback(
    (place?: Partial<Place_FilterFragment>) => {
      setCondition({ ...condition, valueString: place?.id || "" })
    },
    [condition, setCondition]
  )

  const formattedValue = useMemo(() => {
    const options = factOptions[condition.fact as Fact]
    if (!options) return null
    return options.find((option) => {
      return option.value === condition.valueString
    })?.label
  }, [condition.fact, condition.valueString, factOptions])

  if (isAny || isAll) {
    return (
      <div className="text-xs">
        <div className="flex items-center justify-between">
          <div>
            {index === 0 ? "Where" : isParentAll ? "and where" : "or where"}{" "}
            <AnyAllSelect
              value={isAny ? "any" : "all"}
              onValueChange={(value: "any" | "all") => {
                if (value === "any") {
                  setCondition({ any: items })
                } else {
                  setCondition({ all: items })
                }
              }}
            />{" "}
            of the following are true:
          </div>
          {!isRoot && (
            <IconButton onClick={() => deleteCondition(index)}>
              <Trash className="w-4 h-4" />
            </IconButton>
          )}
        </div>

        <div className="border-l-2 border-mercury ml-4 p-4 pr-0 flex flex-col gap-8">
          {items.map((item, index) => (
            <RulesEngineConditionBuilderRow
              key={index}
              isAny={isAny}
              isAll={isAll}
              index={index}
              condition={item}
              deleteCondition={(index) => {
                setCondition(
                  isAny
                    ? {
                        any: items.filter((_, i) => i !== index),
                      }
                    : {
                        all: items.filter((_, i) => i !== index),
                      }
                )
              }}
              setCondition={(
                newCondition: RulesEngineCondition_NestableFragment
              ) => {
                setCondition(
                  isAny
                    ? {
                        any: items.map((i, iIndex) =>
                          iIndex === index ? newCondition : i
                        ),
                      }
                    : {
                        all: items.map((i, iIndex) =>
                          iIndex === index ? newCondition : i
                        ),
                      }
                )
              }}
            />
          ))}

          <div className="flex gap-4">
            <Button
              className="text-2xs"
              onClick={() => {
                setCondition(
                  isAny
                    ? {
                        any: [
                          ...items,
                          {
                            fact: "user.tier.level",
                            operator: RulesEngineConditionOperatorEnum.Equal,
                            valueString: "",
                          },
                        ],
                      }
                    : {
                        all: [
                          ...items,
                          {
                            fact: "user.tier.level",
                            operator: RulesEngineConditionOperatorEnum.Equal,
                            valueString: "",
                          },
                        ],
                      }
                )
              }}
            >
              <Plus className="w-3 h-3 mr-2" />
              Add Condition
            </Button>

            <Button
              className="text-2xs"
              onClick={() => {
                setCondition(
                  isAny
                    ? { any: [...items, { all: [] }] }
                    : { all: [...items, { any: [] }] }
                )
              }}
            >
              <Plus className="w-3 h-3 mr-2" />
              Add Condition Group
            </Button>
          </div>
        </div>
      </div>
    )
  }

  if (!condition.fact) return null

  return (
    <div className="flex items-center gap-2 whitespace-nowrap">
      {index === 0 ? "If" : isParentAll ? "and" : "or"}
      <Select
        value={condition.fact || undefined}
        onValueChange={(value) => {
          const operators = factOperators[value as Fact]
          setCondition({
            ...condition,
            fact: value as string,
            operator: operators.includes(condition.operator!)
              ? condition.operator
              : null,
            valueString: "",
          })
        }}
      >
        <SelectTrigger inline>
          <SelectValue placeholder="Select a field" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="user.tier.level">Tier</SelectItem>
          <SelectItem value="user.expertise_tags">Expertise</SelectItem>
          <SelectItem value="user.interest_tags">Interests</SelectItem>
          <SelectItem value="user.fit_profile.industry">Industry</SelectItem>
          <SelectItem value="user.fit_profile.job_function">
            Job Function
          </SelectItem>
          <SelectItem value="user.fit_profile.vertical">Vertical</SelectItem>
          <SelectItem value="user.place">Location</SelectItem>
        </SelectContent>
      </Select>
      <Select
        value={condition.operator || undefined}
        onValueChange={(value) => {
          console.log({ value })
          setCondition({
            ...condition,
            operator:
              (value as RulesEngineConditionOperatorEnum) ||
              factOperators[condition.fact as Fact][0],
          })
        }}
      >
        <SelectTrigger inline>
          <SelectValue placeholder="Select an operator" />
        </SelectTrigger>
        <SelectContent>
          {factOperators[condition.fact as Fact]?.map((operator) => (
            <SelectItem key={operator} value={operator}>
              {t(`operators.${operator}`)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {condition.fact === "user.place" ? (
        <PlaceFilter
          placeFilter={placeFilter}
          setPlaceFilter={setPlaceFilter}
        />
      ) : (
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="input"
              role="combobox"
              aria-expanded={open}
              className=""
            >
              <span className="overflow-hidden text-ellipsis whitespace-nowrap !inline-block">
                {formattedValue || (
                  <span className="text-placeholder">Select a value</span>
                )}
              </span>

              <div className="flex items-right items-center justify-center">
                <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="max-h-[300px] overflow-y-auto">
            <Command>
              <CommandInput
                placeholder="Search"
                value={query}
                onValueChange={setQuery}
              />
              <CommandGroup>
                {factOptions[condition.fact as Fact]?.map((option) => (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      setCondition({ ...condition, valueString: option.value })
                      setOpen(false)
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <Check
                        className={cn("w-4 h-4", {
                          "opacity-0": condition.valueString !== option.value,
                        })}
                      />
                      <div>{option.label}</div>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      )}
      <IconButton onClick={() => deleteCondition(index)}>
        <Trash className="w-4 h-4" />
      </IconButton>
    </div>
  )
}
