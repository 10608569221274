import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { ComposerOnSave } from "../post-composer/PostComposer"
import { displayErrors } from "~/common/validations"
import invariant from "tiny-invariant"
import toast from "react-hot-toast"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

export const useCreateMessage = ({
  roomId,
  onSuccess,
  otherUserId,
}: {
  roomId?: string | null
  onSuccess: () => void
  otherUserId: string | undefined
}) => {
  const [runPostCreate, postCreateResult] = useSafeMutation(
    MESSAGE_CREATE_MUTATION
  )

  const { logEvent } = useLogEvent()

  const createReply: ComposerOnSave = async ({
    content,
    channelId,
    tagId,
    attachmentIds,
    videoUrl,
  }) => {
    if (!roomId) {
      invariant("room id required")
    }

    const { data, errors } = await runPostCreate({
      variables: {
        input: {
          content,
          channelId,
          tagId,
          attachmentIds,
          roomId,
          videoUrl,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
      return false
    } else {
      toast.success("Created message")
      invariant(data?.postCreate.post.id)

      if (otherUserId) {
        logEvent(AhoyEventTypeEnum.DirectMessageSent, {
          room_id: roomId,
          room_type: data?.postCreate.post.room?.roomType || "unknown",
          user_id_messaged: otherUserId,
        })
      } else {
        logEvent(AhoyEventTypeEnum.DirectMessageSent, {
          room_id: roomId,
          room_type: data?.postCreate.post.room?.roomType || "unknown",
          user_ids: data?.postCreate.post.room?.users
            .map((u) => u.id)
            .join(","),
        })
      }

      onSuccess()
      return true
    }
  }

  return { createReply, replayIsSaving: postCreateResult.loading }
}

const MESSAGE_CREATE_MUTATION = gql(`
  mutation MessageCreate($input: PostCreateInput!) {
    postCreate(input: $input) {
      post {
        id
        room {
          roomType
          users {
            id
          }
        }
      }
    }
  }
`)
