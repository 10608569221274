import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { OnboardingTitle } from "~/ui/Onboarding"
import { useCommunity } from "~/community/useCommunity"
import { LinkButton } from "~/ui/button"
import { feedPath, signUpPath } from "~/common/paths"
import { useNavigate } from "react-router-dom"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useEffect } from "react"
import { CommunityName } from "~/community/CommunityName"
import { Trans } from "react-i18next"
import { Link } from "~/ui/Link"
import { FeatureFlag } from "~/common/FeatureFlag"
import { FeatureFlagEnum } from "~/__generated__/graphql"

export const UnfitScreen = () => {
  const community = useCommunity()

  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser.fit !== false) {
      navigate(feedPath.pattern)
    }
  }, [navigate, currentUser])

  return (
    <OnboardingLayout step={4} hideCommunityFooter>
      <OnboardingTitle>
        <Trans ns="unfitScreen" i18nKey="title" />
      </OnboardingTitle>
      <div className="flex flex-col items-center">
        <div className="text-center max-w-[530px] flex flex-col gap-4">
          <Trans
            ns="unfitScreen"
            i18nKey="body"
            components={{
              CommunityName: <CommunityName />,
              EmailLink: (
                <Link href="mailto:memberships@workweek.com">
                  memberships@workweek.com
                </Link>
              ),
            }}
          />
        </div>
        <LinkButton
          variant="onboarding"
          className="mt-14 py-3 px-7 gap-1"
          to={
            FeatureFlag.get(FeatureFlagEnum.SignupInApp)
              ? signUpPath.pattern
              : community.signUpUrl
          }
        >
          Back to <CommunityName />
        </LinkButton>
      </div>
    </OnboardingLayout>
  )
}
