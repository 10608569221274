import { Users } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { GroupCreateInput } from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { GroupForm } from "~/admin/GroupForm"
import { adminGroupsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

export const AdminGroupNewScreen = () => {
  const [runCreateGroup] = useSafeMutation(GROUP_CREATE_MUTATION)
  const navigate = useNavigate()

  const handleSubmit = async (data: GroupCreateInput) => {
    const response = await runCreateGroup({
      variables: {
        input: data,
      },
    })

    const { data: responseData, errors } = response

    if (errors) {
      displayErrors(errors)
    } else if (responseData?.groupCreate?.group) {
      toast.success("Group created")
      navigate(adminGroupsPath.pattern)
    }

    return response
  }

  return (
    <>
      <AdminHeader
        title="Dynamic Group"
        subtitle="Dynamic groups will automatically include new members who fit the group criteria"
        Icon={Users}
      />

      <GroupForm onSubmit={handleSubmit} />
    </>
  )
}

const GROUP_CREATE_MUTATION = gql(`
  mutation GroupCreate($input: GroupCreateInput!) {
    groupCreate(input: $input) {
      group {
        id
      }
    }
  }
`)
