export const AdminActionToolbar = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <div className="p-4 fixed bottom-0 left-0 right-0 z-40 bg-background border-t border-mercury">
      <div className="flex justify-end items-center gap-4">{children}</div>
    </div>
  )
}
