import { useQuery } from "@apollo/client"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { MessagesSquare, Users } from "lucide-react"
import { useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { gql } from "~/__generated__"
import { Group } from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { AdminTable, AdminTableHeader } from "~/admin/users/UsersTable"
import {
  adminChannelsPath,
  adminGroupEditPath,
  adminGroupNewPath,
  adminGroupsPath,
} from "~/common/paths"
import { Badge } from "~/ui/badge"
import { Button } from "~/ui/button"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import { SearchInput } from "~/ui/SearchInput"
import { TableCell, TableRow } from "~/ui/table"
import { Tabs, TabsList, TabsTrigger } from "~/ui/tabs"

const HEADERS: AdminTableHeader[] = [
  { label: "Group Handle" },
  { label: "Description" },
  { label: "Group Type" },
  { label: "Group Size" },
  { label: "Active" },
  { label: "Actions" },
]

export const AdminGroupsScreen = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300)
  const { data, loading } = useQuery(GROUPS_QUERY_DOCUMENT, {
    variables: { searchTerm: debouncedSearchTerm },
  })
  const navigate = useNavigate()
  const groups = data?.groups.nodes

  return (
    <>
      <AdminHeader title="Channels & Groups" Icon={MessagesSquare}>
        <Button onClick={() => navigate(adminGroupNewPath.pattern)}>
          <Users size={16} className="mr-2" /> Create Dynamic Group
        </Button>
      </AdminHeader>
      <ChannelsAndGroupsTabs />

      <SearchInput
        className="my-4"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search groups"
      />

      <AdminTable items={groups || []} loading={loading} headers={HEADERS}>
        {(group) => <GroupRow key={group.id} group={group} />}
      </AdminTable>
    </>
  )
}

const GroupRow = ({ group }: { group: Partial<Group> }) => {
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)
  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }
  const navigate = useNavigate()

  return (
    <ContextMenu>
      <ContextMenuContent>
        <ContextMenuItem
          onClick={() => navigate(adminGroupEditPath({ id: group.id! }))}
          className="cursor-pointer"
        >
          Edit Group
        </ContextMenuItem>
      </ContextMenuContent>
      <ContextMenuTrigger asChild>
        <TableRow ref={contextMenuTriggerRef} className="group">
          <TableCell className="font-bold">@{group.slug}</TableCell>
          <TableCell>{group.description}</TableCell>
          <TableCell>{group.groupType}</TableCell>
          <TableCell>{group.usersCount}</TableCell>
          <TableCell>
            <Badge variant={group.active ? "default" : "secondaryOutline"}>
              {group.active ? "Yes" : "No"}
            </Badge>
          </TableCell>
          <TableCell>
            <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
              <DotsHorizontalIcon />
            </Button>
          </TableCell>
        </TableRow>
      </ContextMenuTrigger>
    </ContextMenu>
  )
}

export const ChannelsAndGroupsTabs = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const activeTab = useMemo(() => {
    if (location.pathname.includes(adminGroupsPath.pattern)) {
      return "groups"
    } else {
      return "channels"
    }
  }, [location.pathname])

  const onTabChange = (value: string) => {
    if (value === "channels") {
      navigate(adminChannelsPath.pattern)
    } else {
      navigate(adminGroupsPath.pattern)
    }
  }

  return (
    <Tabs value={activeTab} onValueChange={onTabChange}>
      <TabsList className="justify-start mb-4 text-foreground">
        <TabsTrigger value="channels">Channels</TabsTrigger>
        <TabsTrigger value="groups">Groups</TabsTrigger>
      </TabsList>
    </Tabs>
  )
}

const GROUPS_QUERY_DOCUMENT = gql(`
  query AdminGroups($searchTerm: String) {
    groups(search: $searchTerm) {
      nodes {
        id
        slug
        description
        active
        groupType
        usersCount
        createdAt
      }
    }
  }
`)
